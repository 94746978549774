import Link from 'next/link'
import { Button, Icon, Flex, Box, Text, Image, Collapse, VStack } from '@chakra-ui/react'
import '../../home/style/styles.css'
import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { IoNewspaperSharp } from 'react-icons/io5'
import { BsCreditCardFill } from 'react-icons/bs'
import { Logo } from 'components/logo'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { DropdownItem } from '..'

type Props = {
  toggleDrawer: () => void
  isDrawerOpen: boolean
}

const NavDrawer = ({ isDrawerOpen, toggleDrawer }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => setIsOpen(!isOpen)

  return (
    <Box
      w={'full'}
      display={{ md: 'none' }}
      style={{ zIndex: '9999' }}
      px={6}
      className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
      <div className="drawer-content">
        <Flex alignItems={'center'} justifyContent="space-between" pb={20} pt={16}>
          <Logo />
          <Icon as={IoMdClose} boxSize={12} onClick={toggleDrawer} />
        </Flex>
        {/* <Link href="/auth/login">
          <Button
            size="16px"
            w="full"
            variant={'unstyled'}
            border={`1px solid ${colors.brand.darkColors[0]}`}
            height={16}
            px={5}>
            Login
          </Button>
        </Link> */}

        <Box>
          <Flex w="full" align="center" justify="space-between" gap="8px" onClick={toggleMenu}>
            <Text color="brand.surface.high" fontSize="16px" fontWeight="bold">
              Our Resources
            </Text>
            <Icon
              as={isOpen ? FaChevronUp : FaChevronDown}
              fontSize="16px"
              color="brand.surface.high"
            />
          </Flex>
          <Collapse in={isOpen} animateOpacity>
            <VStack
              align="start"
              spacing={2}
              mt={2}
              py="12px"
              borderRadius="md"
              transition="height 0.2s ease-in-out">
              <Link style={{ width: '100%' }} href="/blog">
                <DropdownItem
                  title="Blog"
                  text="Read articles on our blog"
                  icon={<Icon as={IoNewspaperSharp} color="brand.primary" fontSize="24px" />}
                />
              </Link>

              <Link style={{ width: '100%' }} href="/utility-cards">
                <DropdownItem
                  title="Utility Cards"
                  text="Learn more about Lint utility cards"
                  icon={<Icon as={BsCreditCardFill} color="brand.primary" fontSize="24px" />}
                />
              </Link>

              <Link style={{ width: '100%' }} href="/campus-ambassadors">
                <DropdownItem
                  title="Ambassador Program"
                  text="Become a Lint campus ambassador"
                  icon={
                    <Image
                      src="/images/utility/icons/graduation_cap.svg"
                      w="24px"
                      h="24px"
                      alt="icon"
                    />
                  }
                />
              </Link>
              <Link style={{ width: '100%' }} href="/referrals">
                <DropdownItem
                  title="Referrals"
                  text="Invite friends and earn on Lint"
                  icon={
                    <Image
                      src="/images/referrals-landing-page/icons/megaphone_icon.svg"
                      w="24px"
                      h="24px"
                      alt="icon"
                    />
                  }
                />
              </Link>
            </VStack>
          </Collapse>
        </Box>

        <Link href="https://lint.onelink.me/8QeB/1l651f8u">
          <Button size="16px" w="full" mt={10} height={16} px={5}>
            Get the app
          </Button>
        </Link>
      </div>
    </Box>
  )
}

export default NavDrawer
